import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [3,5,6];

export const dictionary = {
		"/(home)": [15,[3]],
		"/(auth)/confirm-email/[token]": [~7,[2]],
		"/(home)/confirm-newsletter": [19,[3]],
		"/(home)/create-project": [20,[3]],
		"/demo": [24],
		"/(home)/faq": [~21,[3]],
		"/(auth)/forgot-password": [8,[2]],
		"/(auth)/forgot-password/success": [9,[2]],
		"/(home)/(legal)/imprint": [16,[3,4]],
		"/(auth)/login": [10,[2]],
		"/(auth)/logout": [~11,[2]],
		"/my": [25,[5]],
		"/my/account": [33,[5]],
		"/my/account/api-keys": [34,[5]],
		"/my/account/payment": [35,[5]],
		"/my/account/payment/authenticate": [~36,[5]],
		"/my/account/payment/stripe/setup": [~37,[5]],
		"/my/account/plan": [~38,[5]],
		"/my/(project)/crawling": [26,[5,6]],
		"/my/(project)/integrations": [27,[5,6]],
		"/my/(project)/integrations/google-tag-manager": [28,[5,6]],
		"/my/(project)/integrations/jssnippet": [29,[5,6]],
		"/my/(project)/overview": [30,[5,6]],
		"/my/(project)/preview": [31,[5,6]],
		"/my/(project)/ui-designer": [32,[5,6]],
		"/(auth)/preauth": [~12,[2]],
		"/(home)/(legal)/privacy": [~17,[3,4]],
		"/(auth)/register": [~13,[2]],
		"/(auth)/reset-password/[slug]": [14,[2]],
		"/(home)/start": [22,[3]],
		"/(home)/start/create": [23,[3]],
		"/(home)/(legal)/terms": [18,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';